import React from "react"
import { Player } from "@lottiefiles/react-lottie-player"
import { BsArrowRight } from "react-icons/bs"
import { Link } from "gatsby"

const Page404 = () => {
  return (
    <>
      <section class="section404">
        <div className="section404__animation">
          <Player
            autoplay={true}
            controls={false}
            loop={true}
            src="https://assets7.lottiefiles.com/packages/lf20_ETG8Dm/88 - 404.json"
            style={{ width: "100%" }}
            background="transparent"
            speed="1"
          ></Player>
        </div>
        <div className="section404__text">Whooops, Page Not Found</div>
        <div className="section404__cta">
          <Link to="/">
            Go To Home <BsArrowRight />
          </Link>
        </div>
      </section>
    </>
  )
}

export default Page404
